<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <b-row>
            <b-col lg="10" offset-lg="1" sm="12">
                <b-overlay :show="loading">
                    <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                        <b-form  @submit.prevent="handleSubmit(register)" @reset.prevent="reset" >
                          <ValidationProvider name="Select Type" vid="type_id" rules="required">
                                <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="formData"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                Select Type <span class="text-danger">*</span>
                                </template>
                                <Select2
                                :options="types"
                                    id="type_id"
                                    rows="6"
                                    v-model="formData.type_id"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    ></Select2>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                                </b-form-group>
                            </ValidationProvider>
                        <ValidationProvider name="Name" vid="name" rules="required">
                            <b-form-group
                            class="row"
                            label-cols-sm="12"
                            label-for="formData"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                            Name <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                                id="name"
                                rows="6"
                                v-model="formData.name"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                            <div class="invalid-feedback">
                                {{ errors[0] }}
                            </div>
                            </b-form-group>
                        </ValidationProvider>
                        <ValidationProvider name="Sort Oder" vid="sort" rules="required">
                            <b-form-group
                            class="row"
                            label-cols-sm="12"
                            label-for="formData"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              Sort <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                                id="sort"
                                rows="6"
                                v-model="formData.sort"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                            <div class="invalid-feedback">
                                {{ errors[0] }}
                            </div>
                            </b-form-group>
                        </ValidationProvider>
                        <ValidationProvider name="Icon" vid="icon_file" rules="">
                            <b-form-group
                            class="row"
                            label-cols-sm="12"
                            label-for="formData"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              Icon<span class="text-danger">*</span>
                            </template>
                            <b-form-file
                                id="icon_file"
                                rows="6"
                                v-model="formData.icon_file"
                                @change="handleImg"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-file>
                            <div class="invalid-feedback">
                                {{ errors[0] }}
                            </div>
                            </b-form-group>
                        </ValidationProvider>
                        <div class="row">
                            <div class="col-sm-3"></div>
                            <div class="col text-right">
                            <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                            &nbsp;
                            <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-1')">Close</b-button>
                            </div>
                        </div>
                        </b-form>
                    </ValidationObserver>
                </b-overlay>
            </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import RestApi, { baseUrl } from '../../config/api_config'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { SECTION_TYPES } from '../../Utils/Constant'
import iziToast from 'izitoast';
export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
      if (this.id) {
          this.formData = this.getItem()
      }
  },
  data () {
    return {
      saveBtnName: this.id ? 'Update' : 'Save',
      formData: {
        'type_id' : null,
        'name' : null,
        'sort' : null,
        'icon_file' : [],
        'status' : 1
      },
      types: SECTION_TYPES
    }
  },
  computed: {
      loading () {
        return this.$store.state.static.loading
      }
  },
  methods: {
    getItem () {
        const item = this.$store.state.list.find(item => item.id === parseInt(this.id))
        return JSON.parse(JSON.stringify({...item, vpn_on: item.vpn_on == 1 ? true : false}))
    },
    handleImg( event ){
        this.formData.icon_file = event.target.files[0];
    },
    async register () {
        this.$store.dispatch('mutedLoad', { loading: true, listReload: false })
        let formData = new FormData()
        let result = null
        Object.keys(this.formData).map(key => {
              formData.append(key, this.formData[key])
        })
        if (this.id) {
            formData.append('_method', 'put')
            result = await RestApi.postData(baseUrl, `${'api/sections/update'}/${this.id}`, formData)
        } else {
            result = await RestApi.postData(baseUrl,'api/sections/store', formData)
        }
        this.$store.dispatch('mutedLoad', { loading: false, listReload: true })
        this.$store.dispatch('dropdownLoad', { hasDropdownLoaded: false })
        if (result.success) {
            iziToast.success({
                title: 'Success',
                message: result.message
            })
             this.$bvModal.hide('modal-1')
        } else {
            this.$refs.form.setErrors(result.errors)
        }
    }
  }
}
</script>
